import React from 'react'
import { Link } from 'react-router-dom'

const Privacy = () => {
    return (
        <>
            <section className='privacy-main-sec'>
                <div className='container'>
                    <div className='text-center'>
                        <h3 className='termstitle'>Privacy Policy</h3>
                    </div>

                    <h5>1. Introduction</h5>
                    <p>Welcome to Short up News. We are committed to protecting your privacy and ensuring your experience with our app is safe and secure. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our app.</p>

                    <h5>2. Information We Collect</h5>
                    <p>Personal Information: We may collect personal information such as your name, email address, and location when you register or interact with our app.</p>
                    <p>Usage Data: We collect information about your interactions with our app, including the pages you visit, the features you use, and the time spent on the app.</p>
                    <p>Device Information: We collect information about the device you use to access our app, including the device type, operating system, and unique device identifiers.</p>


                    <h5>3. How We Use Your Information</h5>
                    <ul>
                        <li>To provide, operate, and maintain our app.</li>
                        <li>To improve, personalize, and expand our app based on your usage.</li>
                        <li>To communicate with you, including sending updates, newsletters, and other relevant information.</li>
                        <li>To analyze usage patterns and trends to enhance user experience.</li>
                        <li>To ensure the security and integrity of our app.</li>
                    </ul>


                    <h5>4. Sharing Your Information</h5>
                    <p>We do not sell or share your personal information with third parties, except in the following circumstances:</p>
                    <ul>
                        <li>With service providers who assist in operating our app.</li>
                        <li>If required by law or to protect our legal rights.</li>
                        <li>In connection with a business transaction such as a merger or acquisition.</li>
                    </ul>


                    <h5>5. Data Security</h5>
                    <p>We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>


                    <h5>6. Your Rights</h5>
                    <p>You have the right to access, update, or delete your personal information. You can do this by contacting us at   <Link>contact@shortupnews.com</Link> </p>


                    <h5>7. Changes to This Privacy Policy</h5>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                    <h5>8. Contact Us</h5>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <Link> contact@shortupnews.com</Link></p>
                </div>
            </section>
        </>
    )
}

export default Privacy